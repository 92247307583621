@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.textinput {
  margin-top: -13px !important;
}

.newdoclink {
  position: absolute !important;
  left: 1px !important;
  bottom: 10px !important;
}

.arrow {
  margin-right: 10px;
}

.tablemargin {
  margin-top: -20px !important;
}

.tablepadding {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.inivitetitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.notetitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.textselect {
  margin-top: -20px !important;
}

.donetext {
  background-color: @primary-color !important;
  color: @white-bg !important;
}

.canceltext {
  border-color: @primary-color !important;
  color: @primary-color !important;
}

.deletebuttontext {
  border-color: @primary-color !important;
  color: @primary-color !important;
  background-color: @layout2-header-background !important;
}

.tooltiplink {
  color: @white-bg;
  text-decoration: underline;
}

.tooltipicon {
  color: #8c8c8c !important;
}

.alertnote {
  width: 80%;
}

.paragraph {
  white-space: pre-line;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.bluelink {
  text-decoration: underline;
}

.whitelink {
  color: @white-bg;
  text-decoration: underline;
}

.datepicker {
  width: 100%;
}

.invitemodal {
  :global {
    .ant-modal-body {
      .dashboardcontent {
        display: none !important;
      }
    }
  }
}

.secondarytext {
  font-weight: 400 !important;
}

.loccontainer {
  width: 100%;
  justify-content: left;
  padding-left: 10px;
}

.infoicon {
  color: #8c8c8c !important;
}

.header {
  white-space: nowrap !important;
}

.textcolor {
  color: #fafafa;
}
