@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.uploadbtn {
  margin-top: 20px;
}

.content {
  padding-top: 20px;
  padding-bottom: 10px;
}

.csvbtn {
  margin-bottom: 24px;
}

.headerinput {
  width: 324px !important;

  @media (max-width: @screen-lg) {
    width: 255px !important;
  }

  @media (max-width: @screen-md) {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
}

.reqdocumentsection {
  display: block;
  margin-top: 20px;
}

.documentsection {
  display: block;
  margin-bottom: 0;
}

.eventitle {
  color: @text-color;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}

.eventbutton {
  padding: 0 !important;
}
