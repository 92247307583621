.quickfilterheader {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
}

.quickfilter {
  background-color: #fff !important;
  width: 100%;
}

.card {
  margin-bottom: 20px;
  border-radius: 10px;
}
