@import 'themes/main.less'; // default application theme

body {
  margin: 0;
  background-color: rgb(82 86 89);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 10px;
  padding: 0;
}

.pagebreak {
  page-break-after: always;
  margin-bottom: 20px;
}

.doccontainer {
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  background: @drawer-bg;

  :global {
    .react-pdf {
      &__Document {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__Page {
        max-width: calc(~'100% - 2em');
        box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
        margin: 1em;

        canvas {
          max-width: 100%;
          width: 100% !important;
          height: auto !important;
        }
      }

      &__message {
        padding: 20px;
      }
    }
  }
}
