@import (reference) 'antd/lib/style/themes/default.less';
@import (reference) 'themes/main-variables.less';

.popover {
  position: relative;
  width: 336px;
}

.noticebutton {
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}

.icon {
  padding: 4px;
  vertical-align: middle;
}

.badge {
  font-size: 16px;
}

.tabs {
  :global {
    .ant-tabs-nav-list {
      margin: auto;
    }

    .ant-tabs-nav-scroll {
      text-align: center;
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
}

.container > * {
  background-color: @popover-bg;
  border-radius: 4px;
  box-shadow: @shadow-1-down;
}

@media screen and (max-width: @screen-xs) {
  .container {
    width: 100% !important;
  }

  .container > * {
    border-radius: 0 !important;
  }
}

.listdesc {
  padding-left: 0;
  padding-right: 10px;
  word-break: break-word;
}

.list {
  max-height: 400px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .item {
    padding-right: 24px;
    padding-left: 24px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;

    .meta {
      width: 100%;
    }

    .avatar {
      margin-top: 4px;
      background: @component-background;
    }

    .iconelement {
      font-size: 32px;
    }

    &.read {
      opacity: 0.4;
    }

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background: @primary-1;
    }

    .title {
      margin-bottom: 8px;
      font-weight: normal;
    }

    .description {
      font-size: 12px;
      line-height: @line-height-base;
    }

    .datetime {
      margin-top: 4px;
      font-size: 12px;
      line-height: @line-height-base;
    }

    .extra {
      float: right;
      margin-top: -1.5px;
      margin-right: 0;
      color: @text-color-secondary;
      font-weight: normal;
    }

    :global {
      .ant-list-item-action {
        margin-left: 10px;
      }
    }
  }

  .loadmore {
    padding: 8px 0;
    color: @primary-6;
    text-align: center;
    cursor: pointer;

    &.loadedall {
      color: rgb(0 0 0 / 25%);
      cursor: unset;
    }
  }
}

.notfound {
  padding: 73px 0 88px;
  color: @text-color-secondary;
  text-align: center;

  img {
    display: inline-block;
    height: 76px;
    margin-bottom: 16px;
  }
}

.bottombar {
  height: 46px;
  color: @text-color;
  line-height: 46px;
  text-align: center;
  border-top: 1px solid @border-color-split;
  border-radius: 0 0 @border-radius-base @border-radius-base;
  transition: all 0.3s;

  div {
    display: inline-block;
    width: 50%;
    cursor: pointer;
    transition: all 0.3s;
    user-select: none;

    &:only-child {
      width: 100%;
    }

    &:not(:only-child):last-child {
      border-left: 1px solid @border-color-split;
    }
  }
}

.footerbtn {
  display: inline-block;
  width: 50%;
  height: 100% !important;
  border-radius: 0 !important;
  border-left: 1px solid @border-color-split !important;
  text-align: center;
}

.ntitle {
  width: 100%;
  word-break: break-word;
  margin-bottom: 0 !important;
}

.rtitle {
  width: 100%;
  word-break: break-word;
  margin-bottom: 0 !important;
}

.ravatar {
  color: #3f51b5 !important;
  background-color: #e6ebf5 !important;
}
