@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.defaultvalue {
  color: @white-bg !important;
}

.nodefaultvalue {
  color: @white-bg !important;
  opacity: 0.5;
  margin-left: 5px;
}

.locationtooltip {
  color: #faad14 !important;
  margin-left: 5px;
}
