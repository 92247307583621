@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.cardstyle {
  border-radius: @border-radius-base !important;
  border: 1px solid #f0f0f0 !important;

  :global {
    .ant-pro-card-body {
      padding: 2px 16px 12px;
      height: 50vh;
      min-height: 300px;
      // overflow: hidden;
    }
  }
}

.gcardstyle {
  border-radius: @border-radius-base !important;
  border: 1px solid #f0f0f0 !important;

  :global {
    .ant-pro-card-body {
      padding: 16px 16px 12px;
      height: 45vh;
      overflow: hidden;
    }
  }
}

.ecardstyle {
  border-radius: @border-radius-base !important;
  border: 1px solid #f0f0f0 !important;
}

.invitecontent {
  overflow-y: auto;
}

.inboundcontent {
  overflow-y: hidden;
  margin-top: 5px;
  max-height: calc(38vh - 25px);
  min-height: 197px;

  @media screen and (max-height: 699px) {
    max-height: 35vh;
  }

  @media screen and (max-height: 599px) {
    max-height: 30vh;
  }
}

.inboundskeletion {
  margin-top: 20px;
}

.overflowhidden {
  overflow: hidden;
}

.line {
  width: 100%;
  height: 100%;
}

.listdesc {
  padding-left: 0;
  padding-right: 10px;
  // width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.paragraph {
  word-wrap: break-word !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: left;

  :global {
    a.ant-typography,
    .ant-typography a {
      text-decoration: underline;
    }
  }
}

.title {
  margin-top: 5px;
}

.calicon {
  color: @primary-color !important;
  font-size: 10px !important;
}

.datepicker {
  width: 80px;

  :global {
    .ant-picker-input input {
      color: @primary-color !important;
    }
  }
}

.list {
  height: 100%;
}

.activetabbadge {
  :global {
    .ant-badge-count {
      background: #3f51b5;
      color: white;
    }
  }
}
