.container {
  width: 100%;
  margin: 20px;
}

.scontainer {
  width: 100%;
}

.skcontainer {
  width: 100% !important;
  border-radius: 5px;
}

.dropdowncontainer {
  padding: 0 8px 4px !important;
}

.dropdowndivider {
  margin: 8px 0 !important;
}

.dropdownlink {
  white-space: nowrap !important;
}

.dropdowninputbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px 4px !important;
}

.dropdowninput {
  min-width: 100% !important;
}

.locationtag {
  font-size: 12px;
}

.dropdowninputgrp {
  padding: 5px 10px;
}

.extimage {
  width: 23px;
  height: 23px;
}

.phonecodedropdown {
  min-width: 200px;
}

.productcontainer {
  width: 100%;
  justify-content: left;
  padding-left: 8px;
}
