@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.alerticon {
  color: @danger !important;
}

.emailtag {
  margin-right: 3px !important;
  background: #f5f5f5 !important;
  border: 1px solid #f0f0f0 !important;
  height: 24px;
  line-height: 22px;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  border-radius: 10px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  user-select: none;
  margin-inline-end: 4px;
  padding-inline-start: 8px;
  padding-inline-end: 4px;
}
