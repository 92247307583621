.tbl {
  margin-top: 24px;
}

.marginleft {
  margin-left: 30px;
  padding: 7px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.marginleft01 {
  margin-left: 30px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.margin30 {
  margin-left: 30px;
}

.margin60 {
  margin-left: 60px;
}

.containertbl {
  padding: 0 61px !important;
}

.containertbl2 {
  padding: 0 57px !important;
}

.titlebar {
  padding: 0 !important;
  margin-bottom: 10px !important;
}

.tabledetails {
  white-space: normal !important;
}

.rowselectwidth {
  width: 100%;
}

.titlebarwarp {
  :global {
    .ant-page-header.has-breadcrumb {
      padding: 0 !important;
    }
  }
}

.antmessage {
  margin-top: 80vh;
}

.activetabbadge {
  border: transparent;

  :global {
    .ant-badge-count {
      background: #3f51b5;
      color: white;
    }
  }
}

.inactivetabbadge {
  border: transparent;
  color: #878787;

  :global {
    .ant-badge-count {
      background: #f5f5f5;
      color: #878787;
    }
  }
}
