@import (reference) 'themes/main-variables.less';

.colorpicker {
  width: 50px !important;
  height: 40px !important;
}

.imagepreview {
  display: none;
}

.uploadtext {
  margin-top: 8px;
  color: @primary-color !important;
}

.title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
}

.padding10 {
  padding: 10px;
  margin-top: -20px;
}

.width10 {
  width: 10px;
}

.desctext {
  white-space: pre-line;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: rgb(0 0 0 / 45%);
}

.imgcrop {
  :global {
    .ant-upload-select {
      background-color: white !important;
      border: 1px dashed rgb(63 81 181/ 100%) !important;
    }
  }
}

.height10 {
  height: 10px;
}

.cropper {
  background-color: white !important;
  height: 300px;
  width: 600px;
  margin-top: 10px;
}

.cropperheader {
  display: flex !important;
  justify-content: space-between !important;
}

.cropperbutton {
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 20px !important;
}

.font16 {
  font-size: 16px !important;
}

.closeicon {
  font-size: 16px;
  color: rgb(0 0 0/ 45%);
}

.cropperdesc {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgb(0 0 0/ 88%);
}

.loadermodal {
  :global {
    .ant-modal-content {
      display: none !important;
    }
  }
}
