.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 9999 !important;

  td {
    padding: 8px 10px 5px;
  }

  .drag-visible {
    visibility: visible !important;
    opacity: 1 !important;
  }

  .actions {
    opacity: 0;
    float: right;
    margin-right: 8px;
  }
}
