@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.uploadbtn {
  margin-top: 20px;
}

.removebtn {
  margin-bottom: 10px;
}

.content {
  padding-top: 20px;
  padding-bottom: 10px;
}

.csvbtn {
  margin-bottom: 24px;
}

.headerinput {
  width: 324px !important;

  @media (max-width: @screen-lg) {
    width: 255px !important;
  }

  @media (max-width: @screen-md) {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
}

.uploadbutton {
  width: 100% !important;
  height: 100% !important;
  background-color: @white-bg !important;

  &:hover {
    background-color: @white-bg !important;
  }
}

.uploadicon {
  color: @text-color !important;
}

.existingdocbtn {
  position: absolute !important;
  left: 0;
  bottom: 10px;
}

.mrb5 {
  margin-bottom: 5px !important;
}

.uploaditem {
  margin-bottom: 10px !important;
}

.uploaditemtext {
  text-align: center;
}

.existingdoctbl {
  margin-top: 10px;
}

.documentmodal {
  :global(.ant-modal-body) {
    height: 70vh;
    overflow: auto;
  }
}

.polytext {
  height: 150px;
  overflow: auto;
}

.textcolor {
  color: #fafafa;
}
