.tagtype {
  font-size: 10px;
  margin-left: 5px;
}

.empty {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit {
  margin-left: 15px;
}

.tagcontainer {
  width: 100%;
  justify-content: left;
}

.tagwidth {
  max-width: 200px !important;
}
