@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.headerdiv {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.button {
  margin-top: 25px;
}
