@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.drawer {
  :global {
    .ant-drawer-close {
      margin-right: 12px !important;
      order: 0 !important;
    }

    .ant-drawer-body {
      padding-top: 8px !important;
    }
  }
}

.potitle {
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  text-align: left;
  color: rgb(0 0 0/88%);
}

.detailsmodal {
  :global(.ant-modal-body) {
    height: 70vh;
    overflow: auto;
    padding-top: 5px !important;
  }
}
