/* row table css */
@bg-color: #fafafa;
@bg-white: #fff;

.box-shadow {
  box-shadow: 0 6px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
}

.row-tbl-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: @bg-white;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  border-radius: 10px;
  overflow: hidden;
}

.row-tbl {
  display: flex;
  width: 100%;
}

.row-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 9px 8px;
  width: 262px;
  background: @bg-white;
}

.row-field-border-bottom {
  border-bottom: 1px solid rgb(0 0 0 / 5%);
}

.row-field-value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 9px 8px;
  width: 524px;
  background-color: @bg-white;
}

.row-field-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 5px;
  width: 168px;
  opacity: 0;
}

.row-tbl:hover .row-field-actions {
  transition: 0.15s linear all;
  opacity: 1;
}

.row-tbl:focus .row-field-actions {
  transition: 0.15s linear all;
  opacity: 1;
}

.row-field-title {
  font-size: 14px;
  margin: 0;
  color: rgb(0 0 0 / 85%);
  font-weight: 600;
  background: @bg-color;
}

.row-field-input {
  width: 80%;
  margin: 0;
  margin-left: 10px;
  border-radius: 10px;
}
