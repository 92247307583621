@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.datepicker {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    //date picker
    .ant-picker-input > input {
      color: #3f51b5;
    }
  }
}

.linechartbg {
  border-radius: 10px !important;
  padding: 5px;
  height: 330px;
  box-shadow: 0 0 4px rgb(0 0 0 / 8%);
}

.headercontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.lefttext {
  font-size: 16px;
  line-height: 24px;
  color: @black-bg;
  font-weight: 600;
}

.line {
  width: 100%;
  height: 80%;
}

.rowheight {
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.innerdiv {
  min-width: 600px;
  height: 100%;
}

.emptycol {
  height: 70% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.linecol {
  height: 30% !important;
}
