.tagtype {
  font-size: 10px;
  margin-left: 5px;
}

.marginleft {
  margin-left: 25px;
  padding: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.containertitle {
  font-size: 14px;
  margin-bottom: 24px;
}

.sharemodalbody {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px !important;
  flex-direction: column;
}

.tooltiplink {
  :global {
    a.ant-typography,
    .ant-typography a {
      color: white;
      text-decoration: underline;
    }
  }
}
