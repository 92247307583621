.modal {
  height: 60vh;
}

.user {
  font-weight: 400 !important;
  font-size: 14px;
}

.actionmenu {
  :global {
    .ant-dropdown-menu-item-group-list {
      .ant-dropdown-menu-title-content {
        margin: 0 8px !important;
      }

      .ant-dropdown-menu-item-selected {
        .ant-dropdown-menu-title-content {
          font-weight: 500;
          margin: 0 8px !important;
        }
      }

      margin: 0 !important;
    }
  }
}
