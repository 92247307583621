@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.uploadbtn {
  margin-top: 20px;
}

.content {
  padding-top: 20px;
  padding-bottom: 10px;
}

.csvbtn {
  margin-bottom: 24px;
}

.ticketinput {
  width: 324px !important;
}

.uploadicon {
  color: @text-color !important;
}

.uploadbutton {
  width: 100% !important;
  height: 100% !important;
  background-color: @white-bg !important;

  &:hover {
    background-color: @white-bg !important;
  }
}

.footertext {
  position: absolute;
  left: 30px;
  bottom: 13px;
}

.tooltiplink {
  :global {
    a.ant-typography,
    .ant-typography a {
      color: white;
      text-decoration: underline;
    }
  }
}

.tooltip {
  margin-right: 2px;
  cursor: help;
}
