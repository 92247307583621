.imagecontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.image {
  width: 150px;
  padding: 20;
}

.imagefull {
  width: 100%;
  padding: 20;
}

.exampletext {
  font-size: 10px;
}

.title {
  margin-bottom: 10px !important;
}

.modal {
  :global {
    .ant-modal-body {
      height: 70vh;
      overflow: auto;
    }
  }
}

.tabpanel {
  max-height: 65vh;
  overflow-y: auto;

  @media (max-width: 992px) {
    max-height: 50vh;
  }
}
