@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.uploadbtn {
  margin-top: 20px;
}

.content {
  padding-top: 20px;
  padding-bottom: 10px;
}

.csvbtn {
  margin-bottom: 24px;
}

.headerinput {
  width: 324px !important;

  @media (max-width: @screen-lg) {
    width: 255px !important;
  }

  @media (max-width: @screen-md) {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
}

.reqdocumentsection {
  display: block;
  margin-top: 20px;
}

.documentsection {
  display: block;
  margin-bottom: 0;
}

.eventitle {
  color: @text-color;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}

.eventbutton {
  padding: 0 !important;
}

.floatbtn {
  position: absolute !important;
  right: 24px;
  top: 24px;
  z-index: 1;
}

.templatename {
  width: 324px !important;
}

.datepicker {
  width: 150px !important;
}

.grptitle {
  font-weight: 600;
  font-size: 14px;
}

.eventdrawer {
  z-index: 1001 !important;

  :global {
    .ant-modal-mask {
      z-index: 1001 !important;
    }

    .ant-modal-wrap {
      z-index: 1001 !important;
    }
  }
}

.emptycard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.emptytext {
  display: block;
}

.upload {
  :global {
    .ant-upload-drag-container {
      height: 300px;
    }
  }
}

.removebtn {
  position: absolute !important;
  right: 24px;
  z-index: 1;
}
