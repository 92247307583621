.marginleft {
  margin-left: 30px;
  padding: 7px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.marginleft01 {
  margin-left: 30px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.margin30 {
  padding-left: 30px;
}

.margin60 {
  padding-left: 60px;
}
