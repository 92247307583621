.dropdowndivider {
  margin: 8px 0 !important;
}

.addproductlink {
  padding: 0 15px 10px 10px;
}

.shipcontainer {
  padding: 0 25px !important;
}

.antmessage {
  margin-top: 80vh;
}

.sscctitle {
  display: block;
  margin-bottom: 12px;
}

.loccontainer {
  width: 100%;
  justify-content: left;
  padding-left: 10px;
}

.marginbottom {
  margin-bottom: 10px !important;
}

.marginleft {
  margin-left: 30px;
  padding: 7px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.marginleft01 {
  margin-left: 30px;
}

.tooltiplink {
  :global {
    a.ant-typography,
    .ant-typography a {
      color: white;
      text-decoration: underline;
    }
  }
}

.prependoption {
  padding-left: 8px;
}
