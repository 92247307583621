.modal {
  height: 60vh;
}

.emailtag {
  background: #f5f5f5 !important;
  border: 1px solid #f0f0f0 !important;
  height: 24px;
}

.title {
  font-weight: normal !important;
}

.invitedes {
  margin-top: 8px;
}

.mrb0 {
  margin-bottom: 0 !important;
}

.actionmenu {
  :global {
    .ant-dropdown-menu-item-group-list {
      .ant-dropdown-menu-title-content {
        margin: 0 8px !important;
      }

      .ant-dropdown-menu-item-selected {
        .ant-dropdown-menu-title-content {
          font-weight: 500;
          margin: 0 8px !important;
        }
      }

      margin: 0 !important;
    }
  }
}
