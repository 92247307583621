.container {
  background-image: url('../../assets/images/access_doc_img.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: right bottom;
  display: flex;
  justify-content: left;
  align-items: left;
  width: 100%;
  height: 100%;
}

.layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.cnt {
  padding: 50px;
  text-align: left;
  align-self: center;
}

.title {
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
}

.description {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 75%;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.backdiv {
  :global {
    .ant-layout {
      background: #ffff;
    }

    .ant-layout-header {
      background: #ffff;
    }
  }
}
