@import (reference) 'antd/lib/style/themes/default.less';
@import 'antd/dist/antd.less';
@import 'main-variables.less';
@import (reference) 'antd/lib/drawer/style/drawer.less';

@pro-column-setting-prefix-cls: ~'@{ant-prefix}-pro-table-column-setting';
@pro-list-prefix-cls: ~'@{ant-prefix}-pro-list';

/** *** Global Overrides *** **/
@tbl-pagination-item-border-radius: 2px;
@segmented-border-radius: 2px;
@drawer-bg: #f7f8fa;
@tree-item-hover-bg: #e6ebf5;

@switch-prefix-cls: ~'@{ant-prefix}-switch';
@pro-form-group-prefix-cls: ~'@{ant-prefix}-pro-form-group';
@select-prefix-cls: ~'@{ant-prefix}-select';

/** *** Drawer *** **/
.@{drawer-prefix-cls} {
  &-close {
    .@{drawer-prefix-cls} & {
      margin-right: 0;
      order: 1;
    }
  }

  &-header {
    background: @drawer-bg;
  }

  &-body {
    background: @drawer-bg;
  }

  &-footer {
    background: @drawer-bg;
  }
}

/**  Table Pagination Items  **/
.@{pagination-prefix-cls} {
  &-item {
    border-radius: @tbl-pagination-item-border-radius;
  }
}

/**  Segmented  **/
.@{segmented-prefix-cls} {
  border-radius: @segmented-border-radius;

  &-item {
    border-radius: @segmented-border-radius;
  }

  &-thumb {
    border-radius: @segmented-border-radius;
  }
}

/** *** Menu *** **/
.@{menu-prefix-cls} {
  background: @drawer-bg;
}

/** *** Layout *** **/
.@{layout-prefix-cls} {
  background: @drawer-bg;
}

/*** radio button ***/

.@{radio-prefix-cls}-button-wrapper {
  &:first-child {
    border-left: @border-width-base @border-style-base @border-color-base;
    border-radius: @border-radius-radio-button 0 0 @border-radius-radio-button;
  }

  &:last-child {
    border-radius: 0 @border-radius-radio-button @border-radius-radio-button 0;
  }

  &:first-child:last-child {
    border-radius: @border-radius-radio-button;
  }
}

.@{pro-column-setting-prefix-cls} {
  &-overlay {
    .@{ant-prefix}-tree-treenode {
      &:hover {
        background-color: @tree-item-hover-bg;
      }
      .@{ant-prefix}-tree-checkbox {
        top: 4px;
        margin: 0;
        margin-right: 4px;
      }
    }
  }
}
.@{switch-prefix-cls} {
  &-small {
    margin-bottom: 2px;
  }
}

/* form group title */
.@{pro-form-group-prefix-cls} {
  &-title {
    font-size: 16px;
    font-weight: 700;
  }
}

.@{pro-column-setting-prefix-cls}-list {
  &-item {
    &-option {
      > span {
        > span.anticon {
          color: @primary-color;
        }
      }
    }
  }
}

@pro-core-label-tip: ~'@{ant-prefix}-pro-core-label-tip';

.@{pro-core-label-tip} {
  &-icon {
    &:hover {
      color: @primary-color;
    }
  }
}

.@{select-prefix-cls} {
  &-dropdown {
    overflow-x: hidden;
  }
}

.@{pro-list-prefix-cls} {
  .@{pro-list-prefix-cls}-row {
    &-title {
      margin-right: 16px;
      word-break: break-all;
      cursor: pointer;

      &:hover {
        color: @primary-color-hover;
      }
    }
  }
}
