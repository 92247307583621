.tbl {
  margin-top: 24px;
}

.containertbl {
  padding: 0 61px !important;
}

.containertbl2 {
  padding: 0 57px !important;
}

.titlebar {
  padding: 0 !important;
  margin-bottom: 10px !important;
}
