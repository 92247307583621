.tabledetails {
  white-space: normal !important;
}

.titlebarwarp {
  :global {
    .ant-page-header.has-breadcrumb {
      padding: 0 !important;
    }
  }
}

.table {
  :global {
    .ant-table.ant-table-small {
      /* stylelint-disable-next-line length-zero-no-unit , color-function-notation */
      box-shadow: none !important;
      overflow: hidden;

      .ant-table-cell {
        background: none !important;
      }

      .ant-table-row {
        &:hover {
          background: #fafafa !important;
        }
      }
    }
  }
}
