@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.button {
  border-color: @primary-color !important;
  color: @primary-color !important;
}

.emptycard {
  height: 300px;
  display: flex;
  justify-content: center;
  width: 100%;
  background: @white-bg;
  /* stylelint-disable-next-line length-zero-no-unit , color-function-notation */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 8%);
  border-radius: 10px;
}

.text {
  white-space: pre-line;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
