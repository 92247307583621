@import (reference) '~antd/es/style/themes/default';

.card {
  :global {
    .ant-card-body {
      padding: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.alist {
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  border-radius: 10px;
  overflow: hidden;
}

.page-header {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.integrationcard {
  margin-bottom: 16px;
  display: block;
}

.integrationcardtitle {
  margin-left: 10px;
}

.integrationcardimg {
  width: 34px !important;
  height: 34px !important;
  object-fit: contain;
}

.integrationcardsettingimg {
  margin-right: 8px;
}

.container {
  padding: 1px !important;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.alert {
  color: red !important;
}

.aligncenter {
  align-items: center;
}

.rowstyle {
  margin-bottom: 10px !important;
  align-items: center !important;
}

.margintop20 {
  margin-top: 20px !important;
}

.servercredentialsparagrapherrormsg {
  margin: 0;
}

.servercredentialloading {
  width: 100%;
  justify-content: center;
  padding: 30px 15px;
}

.servercredentialloadingmodalsuccess {
  width: 100%;
  justify-content: center;
  padding: 10px;
}

.servercredentialloadingmodalsuccessresult {
  padding: 5px 16px !important;
}

.servercredentialtitle {
  font-weight: 400 !important;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.selected {
  color: gray !important;
}

.total {
  color: gray !important;
}

.icon {
  position: relative;
  top: 8px;
}

.learnmore {
  font-weight: normal;
}

.manageimg {
  width: 55px !important;
  height: 55px !important;
  object-fit: contain;
}

.height80 {
  margin-right: 40px !important;
  margin-bottom: 20px;
  width: 100px;
  height: 66px;
}

.loadingservercredentials {
  width: 100px;
  height: 100px;
}

.tagloccontainer {
  width: 100%;
  justify-content: left;
  padding-left: 10px;
}

.margintop10 {
  margin-top: 10px;
}
