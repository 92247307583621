@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.infotitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  position: absolute;
  top: 15px;
}

.infosubtitle {
  font-size: 14px;
  font-weight: 500;
  color: @text-color;
}

.infosubtitlewitherror {
  font-size: 14px;
  font-weight: 500;
  color: @text-color;
  display: flex;
  align-items: center;
}

.infodesc {
  font-size: 14px;
  color: @text-color-secondary;
}

.headerdivider {
  margin-top: 5px !important;
  margin-bottom: 7px !important;
}

.event {
  cursor: pointer;
  width: 100%;
  padding: 5px;
  border-radius: 10px;

  &:hover {
    background-color: @background-color-light;

    .infotitle {
      color: @primary-color;
    }
  }
}

.eventtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eventscontainer {
  width: 100%;
  max-height: 191px;
}

.jsonbox {
  height: 47vh;
  overflow: 'auto';
}

.mrt5 {
  margin-top: 5px;
}

.mrt10 {
  margin-top: 10px;
}

.mrb10 {
  margin-bottom: 10px;
}

.mrb20 {
  margin-bottom: 20px;
}

.mrb0 {
  margin-bottom: 0 !important;
}

.mrl10 {
  margin-left: 10px !important;
}

.cardtitle {
  height: 15px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.digramtext {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 8px;
}

.diagramicon {
  height: 20px !important;
  width: 20px !important;
}

.diagramcard {
  width: 100%;
  margin: 8px 0 10px;
}

.etable {
  :global {
    th.ant-table-cell.ant-table-cell-ellipsis {
      background: none;
    }
  }
}

.detailsmodal {
  :global(.ant-modal-body) {
    height: 70vh;
    overflow: auto;
  }
}

.hashvalidationmodal {
  :global(.ant-modal-body) {
    max-height: 70vh;
    overflow: auto;
  }
}

.titleflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infocontainer {
  width: 250px;
}

.preceive {
  margin: 10px 0;
}

.height80 {
  height: 80vh;
  width: 100%;
  margin: 8px 0 10px;
}

.lifeheader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.tooltip {
  margin-right: 2px;
  cursor: help;
}

.hashvalidateitem {
  display: flex;
  align-items: flex-start;
}

.tooltiplink {
  :global {
    a.ant-typography,
    .ant-typography a {
      color: white;
      text-decoration: underline;
    }
  }
}

.highlight {
  background-color: #fff1f0;
}

.lifecyclecontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hidearrow {
  :global {
    .ant-menu-submenu-title {
      padding-right: 0 !important;
      padding-left: 0 !important;

      .ant-menu-submenu-arrow {
        display: none;
      }
    }
  }
}

.eventcontainer {
  display: flex;
  align-items: center;
}

.eventclosecirlce {
  color: #ff4d4f !important;
  font-size: 16px;
  margin-right: 8px;
}

.deletedpadding {
  padding-right: 70px;
}
