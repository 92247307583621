.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.cnt {
  padding: 150px 254px;
  text-align: center;
}

.subtitle {
  margin-top: 64px;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.description {
  font-size: 14px;
  margin-bottom: 16px;
  line-height: 22px;
  text-align: center;
}

.margin {
  margin-bottom: 32px;
}

.singledescription {
  margin-top: 32px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  width: 100%;
}

.umodaldesc {
  display: block;
  margin-bottom: 16px;
}
