@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.tooltiplink {
  :global {
    a.ant-typography,
    .ant-typography a {
      color: @white-bg;
      text-decoration: underline;
    }
  }
}

.dropdownselect {
  :global {
    .ant-dropdown-menu-item-selected,
    .ant-dropdown-menu-submenu-title-selected {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: rgb(0 0 0 / 85%);
    }
  }

  max-height: 500px;
}

.lefttable {
  width: '100%';
  height: 380px !important;
  margin-right: 20px;
}

.table {
  height: 452px;
  overflow: auto;
}

.container {
  margin-top: 20px;
  border-radius: 20px;
}

.titlecontainer {
  width: 100%;
}

.innercontainer {
  margin-top: -10px;
}

.divcontainer {
  background-color: @white-bg;
  padding: 16px 24px;
  border-radius: 10px;
  height: 380px !important;
  box-shadow: 0 0 4px rgb(0 0 0 / 8%);
}

.switchcontainer {
  background-color: @white-bg;
}

.infowindow {
  margin-right: 10px !important;
  max-width: 236px !important;
}

.infotitle {
  font-weight: 600 !important;
  font-size: 16px !important;
  position: absolute;
  top: 15px;

  :hover {
    color: @primary-color;
  }
}

.infosubtitle {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: rgb(0 0 0 / 85%);

  :hover {
    color: @primary-color;
  }
}

.infodesc {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: rgb(0 0 0 / 45%) !important;
}

.dropdownicon {
  font-size: 10px;
  margin-bottom: 12px;
}

.topmargin {
  margin-top: 20px;
}

.datepicker {
  width: 100%;
}

.shipmenttable {
  height: 170px;
}

.attributetable {
  height: 334px;
}

.canceltext {
  border-color: @primary-color !important;
  color: @primary-color !important;
}

.locationtooltip {
  color: #faad14 !important;
  margin-left: 5px;
}

.popup {
  min-width: 256px !important;
}
