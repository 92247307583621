@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

@white-background: #fff;

.menu {
  height: 100%;
}

.white {
  // overflow: auto;
  height: 100vh;
  background: @white-background;
}

.container {
  padding: 22px 24px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  text-align: 'center';
  width: 100%;
}

.settings-container {
  padding: 0;
}

.settings-content-wrapper {
  padding: 0;
}

.profile-upload {
  margin-left: 5px;
}

.page-header {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.btnicon {
  color: @primary-color !important;
  font-size: 16px;
}

.imagemodal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.imagepreview {
  display: none;
}

.umodaldesc {
  display: block;
  margin-bottom: 16px;
}

.alist {
  box-shadow: 0 2px 2px rgb(0 0 0 / 8%);
  border-radius: 10px;
  overflow: hidden;

  :global {
    .ant-pro-card-body {
      padding: 10px !important;
      // overflow: hidden;
    }
  }
}

.page-header-margin {
  padding-left: 0 !important;
  padding-top: 0 !important;
  margin-top: 20px !important;
}

.orange {
  color: #faad14 !important;
}
