@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.copytext {
  color: @primary-color !important;
}

.checkcolor {
  color: @success-color !important;
}
