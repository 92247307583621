@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.inivitetitle {
  white-space: pre-line;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.canceltext {
  border-color: @primary-color !important;
  color: @primary-color !important;
}
