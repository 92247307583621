@import (reference) 'themes/main-variables.less';

.drawer {
  background-color: @white-bg !important;
}

.procard {
  overflow: auto;
  max-height: 85vh;
}
