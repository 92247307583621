@import 'themes/main.less'; // default application theme

.formbutton {
  // margin-top: 10px;
  position: absolute;
  right: 0;
}

.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: @drawer-bg;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid #f0f0f0;
}

.tooltiplink {
  :global {
    a.ant-typography,
    .ant-typography a {
      color: white;
      text-decoration: underline;
    }
  }
}

.container {
  padding: 20px 100px;

  @media (max-width: @screen-lg) {
    padding: 20px 50px;
  }

  @media (max-width: @screen-md) {
    padding: 20px 25px;
  }

  @media (max-width: @screen-sm) {
    padding: 20px 10px;
  }

  @media (max-width: @screen-xs) {
    padding: 20px 0;
  }
}

.genlayout {
  :global {
    .ant-layout-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.space {
  margin-bottom: 50px;
  width: 78%;
}

.text {
  display: block;
  margin-bottom: 50px;
  margin-top: 30px;
}
