@import (reference) 'themes/main-variables.less';

.padding0 {
  padding: 0 !important;
}

.previewheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%) !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 12px 24px;
}

.previewfooter {
  display: flex;
  background-color: white;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%) !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: center;
  padding: 12px 24px;
  flex-wrap: wrap;
}

.footerlink {
  padding: 0;
  margin-right: 10px;
}

.border0 {
  border-radius: 0;
}

.margintop16 {
  margin-top: 16px;
}

.titlebarwarp {
  :global {
    .ant-page-header {
      padding: 0 !important;
    }
  }
}

.rowselectwidth {
  width: 100%;
}

.labeldesc {
  white-space: normal !important;
  word-wrap: break-word !important;
  overflow: visible !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}

.addsection {
  :global {
    .ant-btn-block {
      width: unset !important;
    }
  }
}

/* styles for barcode generator */
//{{ display: 'flex', alignItems: 'flex-start', gap: '20px' }}
.barcodegenerator-datamatrix {
  display: inline-flex !important;
  flex-flow: column wrap;
  align-items: flex-start !important;
  align-content: stretch !important;
  gap: 10px;
  padding: 8px;
  max-height: 150px;
}

.barcodegenerator-datamatrix-ai-fonts {
  font-size: 12px !important;
  margin: 0;
  padding: 0;
}

.barcodegenerator-datamatrix-pd-10 {
  flex-grow: 1;
  max-height: 150px;
  display: inline-flex !important;
  flex-flow: column wrap;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  align-content: stretch !important;
  padding: 0;
  row-gap: 2px !important;
  column-gap: 10px !important;
  margin-top: -6px;
}

.barcodegenerator-gs1-datamatrix {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
  width: 100%;
  justify-content: center;
}

.barcodegenerator-gs1-datamatrix-inner {
  display: flex;
  justify-content: center;
}

.barcodegenerator-gs1-datamatrix-inner-mr-20 {
  margin-right: 5px;
}

.barcodegenerator-gs1-datamatrix-barcode {
  flex: 1;
  max-width: 400px;
  min-width: 200px;
}

//.barcodegenerato-render-content {
//  display: flex;
//  width: 100%;
//  justify-content: flex-start;
//  padding: 5px;
//}

.barcodegenerato-render-content-pallet {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 1px;
}

/* end barcode generator */
