/* stylelint-disable no-descending-specificity */
@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

@pro-header-hover-bg: rgba(0, 0, 0, 0.025);

.menu {
  :global {
    .ant-dropdown-menu-item {
      min-width: 160px;
    }
  }

  border-radius: 10px !important; // TODO: check other way to do it

  .profile {
    :global(.ant-dropdown-menu-title-content) {
      display: flex;
      flex-direction: column;
    }

    span:nth-child(2) {
      margin-top: 8px;
    }

    pointer-events: none;
  }

  .signout {
    color: @primary-color;
  }
}

.dark {
  .action {
    &:hover {
      background: #252a3d;
    }

    &:global(.opened) {
      background: #252a3d;
    }
  }
}

.right {
  display: flex;
  float: right;
  margin-left: auto;
  height: 48px;
  overflow: hidden;

  button {
    height: inherit;
  }

  .action {
    display: flex;
    align-items: center;
    transition: all 0.3s;

    > span {
      vertical-align: middle;
    }

    &:hover {
      background: @pro-header-hover-bg;
    }

    &:global(.opened) {
      background: @pro-header-hover-bg;
    }
  }

  .account {
    .avatar {
      color: @primary-color;
      vertical-align: top;
      margin: auto 8px;
      background: rgb(255 255 255 / 85%);
      width: 32px;
      height: 32px;
    }
  }
}

@media only screen and (max-width: @screen-md) {
  :global(.ant-divider-vertical) {
    vertical-align: unset;
  }

  .right {
    position: absolute;
    top: 0;
    right: 12px;

    .account {
      .avatar {
        margin-right: 0;
      }
    }

    .search {
      display: none;
    }
  }
}

.accountd {
  position: absolute;
  left: 25px;

  @media (max-width: @screen-md) {
    position: fixed;
    left: 95px;
  }
}

.accounttext {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c !important;
}

.avatar {
  color: #3f51b5 !important;
  background-color: #e6ebf5 !important;
  margin-bottom: 5px !important;
}

.container {
  display: flex;
  justify-content: 'space-between';
  align-items: 'center';
  width: 100%;
}

.accountname {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
}

.searchinput {
  display: flex;
  flex: 4;
  justify-content: center;
  align-items: center;
}

.searchbutton {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
