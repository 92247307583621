@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  width: 150px;
}

.headermargin {
  margin-top: 5px;
  margin-bottom: 24px;
  margin-left: 10px;
}

.leftpadding {
  padding-right: 20px;
}

.rightpadding {
  padding-right: 10px;
}

.dropdownicon {
  font-size: 10px !important;
  color: @primary-color !important;
}
