@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
  height: 100%;
  width: 100%;
}

.list {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  border-radius: @border-radius-base !important;
  overflow: hidden;
  background: #fff;
  border: 1.5px solid rgb(0 0 0 / 14%) !important;

  :global {
    .ant-pro-table-list-toolbar-container {
      display: block !important;
    }

    .ant-pro-table-list-toolbar-title {
      display: block !important;
      width: 100% !important;
    }

    .ant-list-item {
      border-radius: @border-radius-base !important;
      overflow: hidden;
      background: #fff;
      border: 1.5px solid rgb(0 0 0 / 14%) !important;
      margin-bottom: 16px;
    }
  }
}

.icon {
  color: @primary-color !important;
}

.search {
  width: 100%;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 16px;
  display: block;
}
