@import (reference) 'themes/main-variables.less';

.container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 9999;
}

.spin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255 255 255 90%);
  height: 80px;
  width: 80px;
  border-radius: 5px;
}

.progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255 255 255 90%);
  border-radius: 5px;
}

.fullopacity {
  background: rgba(255 255 255) !important;
}

.floating {
  top: 0;
}

.margin {
  margin-top: 10px;
}

.column {
  flex-direction: column;
}

.title {
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 40px;
}

.line {
  width: 30% !important;
}
