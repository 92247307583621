@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

@lg-screen: 1172px;
@md-screen: @screen-md + 30px;

.uploadbtn {
  margin-top: 20px;
}

.removebtn {
  margin-bottom: 10px;
}

.content {
  padding-top: 20px;
  padding-bottom: 10px;
}

.csvbtn {
  margin-bottom: 24px;
}

.headerinput {
  width: 324px !important;

  @media (max-width: @lg-screen) {
    width: 255px !important;
  }

  @media (max-width: @md-screen) {
    width: 100% !important;
    margin-bottom: 10px !important;
  }

  @media (max-width: @screen-sm) {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
}

.headerinput1 {
  width: 324px !important;

  @media (max-width: @lg-screen) {
    width: 255px !important;
  }

  @media (min-width: 912px) and (max-width: @lg-screen) {
    margin-left: 24px !important;
  }

  @media (max-width: @md-screen) {
    width: 100% !important;
    margin-bottom: 10px !important;
  }

  @media (max-width: @screen-sm) {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
}

.uploadbutton {
  width: 100% !important;
  height: 100% !important;
  background-color: @white-bg !important;

  &:hover {
    background-color: @white-bg !important;
  }
}

.uploadicon {
  color: @text-color !important;
}

.existingdocbtn {
  position: absolute !important;
  left: 0;
  bottom: 10px;
}

.mrb5 {
  margin-bottom: 5px !important;
}

.uploaditem {
  margin-bottom: 10px !important;
}

.uploaditemtext {
  text-align: center;
}

.existingdoctbl {
  margin-top: 10px;
}

.location {
  :global {
    .ant-select-selection-placeholder {
      font-weight: normal !important;
    }
  }
}

.workflowmodal {
  z-index: 1001 !important;

  :global {
    .ant-modal-mask {
      z-index: 1001 !important;
    }

    .ant-modal-wrap {
      z-index: 1001 !important;
    }
  }
}

.tooltiplink {
  :global {
    a.ant-typography,
    .ant-typography a {
      color: white;
      text-decoration: underline;
    }
  }
}

.documentmodal {
  :global(.ant-modal-body) {
    height: 70vh;
    overflow: auto;
  }
}

.reqdocumentsection {
  display: block;
  margin-top: 20px;
}

.documentsection {
  display: block;
  margin-bottom: 0;
}

.header {
  white-space: nowrap !important;
}

.tooltip {
  margin-right: 2px;
  cursor: help;
}

.margint20 {
  margin-top: 20px;
}

.receivemodal {
  :global(.ant-modal-body) {
    height: 70vh;
    overflow: auto;
  }
}
