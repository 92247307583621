@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.genlayout {
  :global {
    .ant-layout-header {
      background: @layout2-header-background;
    }

    .ant-pro-top-nav-header {
      box-shadow: none;
    }

    .ant-layout-content {
      margin: 20px 50px;

      @media (max-width: @screen-sm) {
        padding: 20px 0;
      }

      @media (max-width: @screen-xs) {
        padding: 20px 0;
      }
    }
  }
}
