.fields-grid {
  margin: 24px 0;
}

.field-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 8px;
  border: 1px solid transparent;

  &:hover {
    border-color: #f0f0f0;
    background: #fafafa;
  }
}

.field-label {
  font-size: 12px;
  color: rgb(0 0 0 / 45%);
  margin-bottom: 4px;
}

.field-value {
  font-size: 13px;
  color: rgb(0 0 0 / 85%);
  min-height: 22px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
}

/* barcode pallet CSS */

.pallet-label-card-container-empty {
  min-width: 100%;
  min-height: 2in;
  margin: 0 auto;
  padding: 24px;
  border: 1px solid #c3c3c3;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pallet-label-container {
  padding: 24px;
  background: #fff;
  min-height: 600px;
  width: 100%;
  border: none;
  font-family: helvetica, sans-serif;
  max-width: 56rem;
  //border: 1px solid #d9d9d9;
}

.header-section {
  margin-bottom: 32px;
  text-align: center;
}

.barcode-section {
  width: 100%;
  margin: 32px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.barcode-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  :global {
    canvas {
      max-width: 80% !important;
      height: auto !important;
      margin: 0 auto;
    }
  }
}

.barcode-text {
  display: block;
  margin-top: 16px;
  font-family: monospace;
  font-size: 16px;
  text-align: center;
  width: 100%;
}

.content-section {
  display: flex;
  justify-content: center;
  margin: 0;
  overflow: hidden;
  //max-width: 56rem;
}

.section-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #c3c3c3;
  height: 160px;
  width: 100%;
  padding: 0.5rem;
  overflow: hidden;
  position: relative;
}

.section-title {
  display: block;
  font-size: 16px;
}

.section-content {
  display: flex;
  gap: 8px;
  overflow: hidden;
  flex: 1;
  width: 100%;
}

.section-pallet-barcode {
  display: flex;
  width: 100%;
  border: 0.5px solid #c3c3c3;
}

.section-data {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
}

.section-data-barcode {
  display: flex;
  flex-direction: column;
}

.section-data-datamatrix {
  display: flex;
  flex-direction: row;
}

.data-line {
  display: block;
  font-size: 14px;
  line-height: 1.5;
  overflow: hidden; /* Hide overflow */
}

/* print label */
.print-container {
  display: block;
  width: 100%;
  page-break-inside: avoid;
}

@media print {
  .barcode-wrapper {
    max-width: 100% !important;

    :global {
      canvas {
        max-width: 100% !important;
        height: 105px !important;
      }
    }
  }

  /* Force flex container to allow stretching */
  .content-section {
    display: flex !important;
    align-items: stretch !important; /* Critical for equal height */
    margin-bottom: 0;
  }

  /* Columns and containers take full height */
  .ant-col {
    height: 100% !important;
  }

  .printable-pallet-only-section-container {
    height: 100% !important;
    display: flex !important;
    flex-direction: column;
    box-sizing: border-box; /* Include padding in height */
  }

  /* Fix barcode alignment */
  .printable-pallet-only-barcode-wrapper {
    height: 100%;
    display: flex !important;
    align-items: center; /* Center barcode vertically */
    justify-content: center; /* Center barcode horizontally */
  }
}

//printable class for pallet layout
.printable-pallet-only-section-container {
  border: 0.5px solid #686d76;
  border-top-color: white;
  padding: 1px;
  margin: 0 auto;
  page-break-inside: avoid;

  /* Make this container take full height of parent column */

  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
}

/* Make each row display as flex to allow equal heights */

.printable-pallet-content-section {
  display: flex;
  width: 100%;
}

/* Make columns take full height of their row */
.printable-pallet-content-section .ant-col {
  display: flex;
  flex-direction: column;
}

.printable-pallet-only-label-container {
  background: #fff;
  min-height: 400px;
  width: 100%;
}

.printable-pallet-only-section-title {
  display: block;
  font-size: 10px;
  margin-bottom: 2px;
  padding: 4px;
}

.printable-pallet-only-section-content {
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 4px;
  margin-bottom: 0.3rem;

  /* Make this section expand to fill available space */
  flex: 1;
  height: 100%;
  justify-content: flex-start;
}

.printable-pallet-only-barcode-section {
  margin-bottom: 10px;
  width: 100%;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;

  .printable-pallet-only-barcode-wrapper {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 0;

    :global {
      canvas {
        width: 100% !important;
        height: auto !important;
        max-height: 120px;
        display: block;
        margin: 0 auto;
      }
    }
  }

  .printable-pallet-only-barcode-text {
    display: block;
    margin-top: 5px;
    font-family: monospace;
    font-size: 8px;
    text-align: center;
    width: 100%;
  }
}

.printable-pallet-only-data-line {
  display: block;
  font-size: 8px;
  line-height: 1.3;
}

//End printable pallet only =======

// printable class for case layout
.printable-fields-grid {
  margin: 0;
  margin-bottom: 6px; /* Reduced margin */
}

.printable-pallet-label-container {
  border: none; /* Remove border in print */
  margin: 0;
  font-family: helvetica, sans-serif;
  padding: 1rem;
}

.printable-field-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 0; /* Reduced margin */
  padding: 0; /* Reduced padding */
}

.printable-field-label {
  font-size: 9px; /* Reduced font size */
  color: rgb(0 0 0 / 90%);
  margin-bottom: 2px;
}

.printable-field-value {
  font-size: 10px; /* Reduced font size */
  color: rgb(0 0 0 / 80%);
  min-height: 4px;
  line-height: 1;
  font-weight: bold;
}

// Logo and Brand Card Styles
.printable-logo-card {
  height: 60px;
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  margin-bottom: 3px;
  margin-left: 0;
  padding-left: 0;
}

// Header Title Styles
.printable-main-title {
  margin: 0;
  font-size: 18px;
}

.printable-subtitle {
  margin: 2px 0;
  font-weight: normal;
  font-size: 12px;
}

.printable-processed-text {
  font-size: 10px;
}

// Logos Container Style
.printable-logos-container {
  width: 100%;
}

// Barcode Section Styles
.printable-barcode-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

.printable-barcode-container-main {
  border: none;
  height: 130px;
  display: flex;
  justify-content: flex-start;
  margin-left: 0;
  padding-left: 0;
}

.printable-wholechain-logo {
  width: 30px;
  height: 20px;
}

.printable-voice-pick-code {
  border: 1px solid #000;
  height: 20px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.printable-voice-pick-text {
  color: #fff;
  font-size: 14px;
  margin: 0;
}

// Main Card Styles
.printable-main-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

// 4x12 Layout Specific Styles
.printable-double-card-container {
  display: flex;
  gap: 8px; // equivalent to antd's small space
}

.case-label-wrapper {
  width: 100%;
  max-width: 56rem; /* equivalent to max-w-4xl */
  margin: 0 auto;
  padding: 1rem;
}

.case-label-container {
  position: relative;
  width: 100%;

  /* 60.67 / 66.67% comes from 4/6 (height/width) ratio */
  padding-top: 60.67%;
}

.case-label-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #c3c3c3;
  display: flex;
  flex-direction: column;
}

.case-label-content-header {
  height: 30.67%; /* 1/6 of total height */
  padding: 0.5rem;

  /* Prevent header from growing */
  //flex-shrink: 0;
}

.case-label-content-body {
  flex-grow: 1;
  background-color: white;
  padding: 1rem;
  padding-top: 0.1rem;

  /* Add overflow handling */

  /* Prevent body from expanding beyond its space */

  min-height: 0;
  height: calc(66.66% - 2px); /* Subtracting borders */
  overflow: hidden;
}

.case-label-content-body-empty {
  flex-grow: 1;
  background-color: white;
  padding: 1rem;
  padding-top: 0.1rem;

  /* Add overflow handling */

  /* Prevent body from expanding beyond its space */
  min-height: 0;
  height: calc(66.66% - 2px); /* Subtracting borders */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.case-label-content-footer {
  height: 16.67%; /* 1/6 of total height */
  background-color: #f9fafb;
  padding: 1rem;
  border-top: 1px solid #e5e7eb;

  /* Prevent footer from growing */
  flex-shrink: 0;
}

.case-label-card-container-printing-settings {
  width: 100%;
  max-width: 56rem; /* equivalent to max-w-4xl */
  margin: 0 auto;
  padding: 1rem;
}

.case-label-card-container-empty {
  min-width: 100%;
  min-height: 4in;
  margin: 0 auto;
  padding: 24px;
  border: 1px solid #c3c3c3;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.case-label-card-barcode-container {
  border: none;
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: flex-start;
  max-width: 100%;
  overflow: hidden;
}

.case-label-card-barcode-container-empty {
  border: 1px dotted #c3c3c3;
  display: flex;
  padding: 0;
  margin: 5px;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #fafafa;
  min-height: 150px;
  margin-bottom: 1%;
}

.case-label-voice-pick-code-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  min-height: 150px;
  margin-bottom: 10%;
}

.case-label-voice-pick-code-container-printable {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  min-height: 100px;
  margin-bottom: 10%;
}

.case-label-wholechain-logo {
  height: 60px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: #fff;
}

.case-label-card-voice-pick-code {
  border: 1px solid #000;
  min-height: 4.06rem;
  border-radius: 0;
  background: #000;
  margin-top: 0.7rem;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.case-label-card-voice-pick-code-printable {
  border: 1px solid #000;
  min-height: 2.06rem;
  border-radius: 0;
  background: #000;
  margin-top: 0.7rem;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.case-label-card-voice-pick-code-text {
  color: #fff !important;
  font-size: 24px;
  margin: 0;
}

.case-label-card-voice-pick-code-text-special-digit {
  font-size: 37px;
  font-weight: 600;
  margin-left: 0.1rem;
}

.case-label-card-voice-pick-code-text-printable {
  color: #fff !important;
  font-size: 20px;
  margin: 0;
}

.case-label-brand-logo {
  border: none;
  //height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 3px;
}

.case-label-certification-logo {
  border: none;
  //height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 3px;
}

.case-label-logo-url {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.case-label-product-name {
  margin: 0;
  font-size: 28px;
}

.case-label-product-description {
  margin: 4px 0;
  font-weight: normal;
}

.case-label-logos-container {
  width: 100%;
}

.case-label-wrapper-printable {
  width: 6in;
  height: 4in;
  padding: 0;
  margin: 0;
}

.case-label-container-printable {
  position: relative;
  width: 100%;
  height: 100%;
}

.case-label-content-printable {
  position: relative;
  width: 100%;
  height: 100%;
  border: 0.01in solid #e5e7eb; /* Thin border for printing */
  display: flex;
  flex-direction: column;
}

.case-label-content-header-printable {
  height: 0.7in; /* 1/6 of 4 inches */
  padding: 0.125in;
  flex-shrink: 0;
}

.case-label-content-body-printable {
  flex-grow: 1;
  background-color: white;
  padding: 0.125in;
  overflow-y: auto;
  min-height: 0;
}

.case-label-product-name-printable {
  margin: 0;
  font-size: 28px;
  font-weight: 600;
}

.case-label-product-description-printable {
  margin: 0;
  font-size: 11px;
  font-weight: 600;
}

.case-label-brand-logo-printable {
  border: none;
  width: 3.5rem;
  height: 3.5rem;
  //height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 3px;
}

.case-label-product-description-processed-usa-printable {
  font-size: 10px; /* Reduced font size */
  color: rgb(0 0 0 / 45%);
}

.case-label-product-description-processed-usa {
  font-size: 12px; /* Reduced font size */
  color: rgb(0 0 0 / 45%);
}

.case-label-content-printing-settings {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  flex-direction: column;
}

.case-label-title-printing {
  margin-bottom: 0.6rem;
}

.case-label-wrapper-printing-settings {
  width: 100%;
  max-width: 56rem; /* equivalent to max-w-4xl */
  margin: 0 auto;
  padding: 0.2rem 1rem 1rem;
}

/* PTI Label */
.case-pti-label-wrapper {
  width: 100%;
  max-width: 48rem; /* equivalent to max-w-4xl */
  margin: 0 auto;
  padding: 1rem;
  font-size: 12px;
  font-family: helvetica, sans-serif;
}

.case-pti-label-container {
  position: relative;
  width: 100%;

  /* 50% comes from 2/4 (height/width) ratio */
  padding-top: 50%;
}

.case-pti-label-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #c3c3c3;
  display: flex;
  flex-direction: column;
}

.case-pti-label-content-header {
  height: 10.67%; /* 1/6 of total height */
  padding: 1rem;

  /* Prevent header from growing */
  //flex-shrink: 0;
}

.case-pti-label-voice-pick-code-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 87%;
  min-height: 150px;
  margin-bottom: 10%;
  margin-left: 0.4rem;
}

.case-pti-label-card-voice-pick-code {
  border: 1px solid #000;
  min-height: 2.06rem;
  border-radius: 0;
  background: #000;
  margin-top: 0.7rem;
  margin-right: 0;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.case-pti-label-card-voice-pick-code-text {
  color: #fff !important;
  font-size: 20px;
  margin: 0;
}

.case-pti-label-card-voice-pick-code-text-special-digit {
  font-size: 34px;
  font-weight: 600;
  margin-left: 0.5rem;
}

.case-pti-label-card-voice-pick-code-text-printable {
  color: #fff !important;
  font-size: 12px;
  margin: 0;
}

.case-pti-label-card-voice-pick-code-text-special-digit-printable {
  font-size: 15px;
  font-weight: 600;
  margin-left: 0;
}

.case-pti-label-container-printable {
  position: relative;
  width: 100%;
  height: 100%;
}

.case-pti-label-wrapper-printable {
  width: 4in;
  height: 2in;
  padding: 0;
  margin: 0;
  font-size: 10px;
  font-family: helvetica, sans-serif;
  flex-shrink: 0;
  border: solid 1px #c3c3c3;
}

.case-pti-label-content-header-printable {
  height: 0.26in; /* 1/6 of 2 inches */
  padding: 0.0625in; /* Reduced padding for smaller label */
  flex-shrink: 0;
}

.case-pti-label-content-body-printable {
  flex-grow: 1;
  background-color: white;
  padding: 0.0625in;
  overflow: hidden;
  min-height: 0;
  height: auto;
  flex-shrink: 0;
}

.case-pti-label-voice-pick-code-container-printable {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 60%;
  min-height: auto;
  margin-bottom: 5%;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.case-pti-label-card-voice-pick-code-printable {
  border: 1px solid #000;
  min-height: 2.06rem;
  border-radius: 0;
  background: #000;
  margin-top: 0.7rem;
  margin-right: 0;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.case-pti-label-product-name-printable {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.case-pti-label-printable-field {
  font-size: 10px; /* Reduced font size */
  color: rgb(0 0 0 / 80%);
  min-height: 4px;
  line-height: 1;
  font-weight: bold;
}

.case-pti-label-printable-field-value {
  font-size: 6px; /* Reduced font size */
  color: rgb(0 0 0 / 80%);
  min-height: 2px;
  line-height: 1;
  font-weight: bold;
}

.printable-fields-grid-pti {
  margin: 0;
  margin-bottom: 2px;
}

.printable-field-label-pti {
  font-size: 7px; /* Reduced font size */
  color: rgb(0 0 0 / 90%);
  margin-bottom: 2px;
}

.case-pti-label-footer {
  height: 0.28in; /* 1/6 of 2 inches */
  padding: 0.0625in;
  flex-shrink: 0;
}

.case-pti-label-card-barcode-container-printable {
  flex: 3; /* Takes 75% of space */
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  overflow: hidden;
  //border: none;
  //display: flex;
  //padding: 0;
  //margin: 0;
  //justify-content: flex-start;
  //max-width: 100%;
  //overflow: hidden;
}
