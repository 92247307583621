@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.textinput {
  margin-top: -13px !important;
}

.newdoclink {
  position: absolute !important;
  left: 1px !important;
  bottom: 10px !important;
}

.arrow {
  margin-right: 10px;
}

.tablemargin {
  margin-top: -20px !important;
}

.tablepadding {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.dropdownwidth {
  width: 100%;
}

.donetext {
  background-color: @primary-color !important;
  color: @white-bg !important;
}

.canceltext {
  border-color: @primary-color !important;
  color: @primary-color !important;
}
