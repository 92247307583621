.tooltiplink {
  :global {
    a.ant-typography,
    .ant-typography a {
      color: white;
      text-decoration: underline;
    }
  }
}

.mrb5 {
  margin-bottom: 5px !important;
}

.supportlink {
  color: #ffff;
}
