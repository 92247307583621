@import (reference) 'antd/lib/style/themes/default.less';
@import (reference) 'themes/main-variables.less';

.table {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global {
    .ant-table.ant-table-small {
      /* stylelint-disable-next-line length-zero-no-unit , color-function-notation */
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 8%);
      overflow: hidden;
    }

    .ant-pro-table-alert {
      display: none;
    }

    .ant-pro-table-list-toolbar-container {
      padding-top: 0;
    }

    .ant-pro-table-list-toolbar-right {
      .ant-pro-table-list-toolbar-setting-item {
        &:hover {
          color: @primary-color;
        }
      }
    }

    .ant-pro-table-list-toolbar-left {
      width: 100%;

      .ant-pro-table-list-toolbar-title {
        width: inherit;

        .titlebar-warp {
          width: inherit;

          .ant-page-header {
            padding: 0 @padding-md 0 0;
          }
        }
      }
    }

    .ant-table-row {
      .actions {
        opacity: 0;
        float: right;
        margin-right: 8px;
      }

      .ant-space {
        margin-right: 8px;
        float: right;
      }

      .text-link {
        color: @black-bg;
      }

      .ant-typography-copy {
        opacity: 0;
      }

      &:hover {
        .actions {
          opacity: 1;
        }

        .text-link {
          color: @primary-color;

          .ant-typography {
            color: @primary-color;
          }
        }

        .ant-typography-copy {
          opacity: 1;
        }
      }

      .ant-dropdown-open.actions {
        opacity: 1;
      }

      .value-actions-btn {
        a {
          color: @primary-color;
          font-size: 16px;
        }
      }
    }
  }
}

.toolbar {
  display: flex;
  justify-content: flex-end;
  margin-top: @margin-lg;
}

.addtopbtn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: @margin-lg;
}

.addtopleftbtn {
  display: flex;
  justify-content: flex-start;
  margin-bottom: @margin-lg;
}

.sktcontainer {
  padding: 5px 10px;
}

.sktcontainermain {
  padding: 15px;
}

.deletebutton {
  color: #6374c2 !important;
  font-size: 18px;
  margin-top: 4px;
}
