.actions {
  opacity: 0;
}

:global(.ant-table-row) {
  &:hover {
    .actions {
      transition: 0.15s linear all;
      opacity: 1;
    }
  }
}

.page-header {
  padding-left: 0 !important;
  padding-top: 0 !important;
}
