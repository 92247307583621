.card {
  margin-bottom: 20px;
  border-radius: 10px;
}

.margintop8 {
  margin-top: 8px;
}

.margintopn8 {
  margin-top: -8px;
}
