.content {
  :global {
    .ant-pro-page-container-children-content {
      margin: 0;
    }

    .ant-pro-page-container-warp,
    .ant-page-header {
      background-color: rgb(0 0 0 / 0%);
    }
  }
}
