@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.inivitetitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0 0 0 / 85%);
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0 0 0 / 85%);
}

.warntitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0 0 0 / 85%);
}

.deletebuttontext {
  border-color: @primary-color !important;
  color: @primary-color !important;
  background-color: @layout2-header-background !important;
}

.disabledbuttontext {
  border-color: rgba(0 0 0 / 25%) !important;
  color: rgba(0 0 0 / 25%) !important;
  background-color: @layout2-header-background !important;
}

.whitebutton {
  border-color: @primary-color !important;
  color: @primary-color !important;
  background-color: @white-bg !important;
}
