@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.documentupload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  background-color: @white-bg;
}

.infotitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  position: absolute;
  top: 15px;
}

.infosubtitle {
  font-size: 14px;
  font-weight: 500;
  color: rgb(0 0 0 / 85%);
}

.infodesc {
  font-size: 14px;
  color: rgb(0 0 0 / 85%);
}

.tagcontainer {
  margin-bottom: 16px;
}

.tagelement {
  display: inline-block;
  margin-bottom: 10px;
}

.taginput {
  width: 78px !important;
}

.documentheader {
  margin-bottom: 0 !important;
}

.tagtype {
  font-size: 10px;
}

.tagfieldinput {
  background: #f5f5f5 !important;
  border: 1px solid #f0f0f0 !important;
  height: 24px;
  line-height: 22px;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  border-radius: 10px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  user-select: none;
  margin-inline-end: 4px;
  padding-inline-start: 8px;
  padding-inline-end: 4px;

  :global {
    .tagtype {
      display: none;
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
}

.viewdocumentmodal {
  :global(.ant-modal-body) {
    height: 70vh;
    overflow: auto;
  }
}

.notedesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.notetitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.tagheader {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgb(0 0 0 / 85%);
}
