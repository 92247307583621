@import (reference) '~antd/es/style/themes/default';

.alist {
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  border-radius: 10px;
  overflow: hidden;
}

.listmrt20 {
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
}

.page-header {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.langselect {
  width: 150px !important;
  text-align: left !important;
}
